import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaCheck, FaEdit, FaHandPeace, FaPencilAlt, FaUser } from 'react-icons/fa';
import { useAppStore } from '../AppStore/AppStore';
import { Collapse } from '@mui/material';
import { useEffect } from 'react'
import axios from 'axios';
import Alert from '@mui/material/Alert';
import FullWidthGrid from '../CustomerDashboardHomePage/CustomerDashboardHomePage';


const drawerWidth = 250;



const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});




const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function Sidebar() {


    const [open3, setOpen3] = React.useState(false);

    const [isAlert, setAlert] = React.useState()

    const kycStatus = () => {
        const email = sessionStorage.getItem('email')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/users/kyc/status', {
            email,
            type
        }).then((res) => {
            if ((res.data.isAadhar === 1 && res.data.isAddGst === 1) || (res.data.isAadhar === 1 && res.data.isAddGst === 2)) {
            } else {
                setTimeout(() => setAlert(true))
            }
        })
    }

    useEffect(() => {
        kycStatus()
        setOpen3(true)
        setOpen3(true)
    }, [])

    const [isUpdateKyc, setIsUpdateKyc] = useState(false)
    const [isCreateBids, setisCreateBids] = useState(false)
    const [isProfile, setIsProfile] = useState(false)
    const [lastName, setLastName] = useState()
    const [profileItem, setIsprfileItem] = useState(false)

    useEffect(() => {

        setTimeout(() => {

        })
    }, [])


    const [mobile, setMobile] = useState('')
    const [firstName, setFirstName] = useState('')
    const [aadhar, setAadhar] = useState('')
    const [firm, setFirm] = useState('')
    const [business, setBusiness] = useState()
    const [newGst, setNewGst] = useState('')
    const [newEmail, setNewEmail] = useState()
    const [newMobile, setNewMbile] = useState()
    const [email, setEmail1] = useState()

    const userName = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/profile', {
            email
        }).then(async (res) => {
            setFirstName(res.data.first_name)
            setLastName(res.data.last_name)
            await setFirstName(res.data.first_name)
            setLastName(res.data.last_name)
            const CCEncoded2 = "XXXX " + res.data.email.substring(res.data.email.length + 4, 6);
            setEmail1(res.data.email)
            setNewEmail(CCEncoded2)
            const CCEncoded3 = "XX XXXX " + res.data.mobile.substring(res.data.mobile.length + 4, 6);
            setNewMbile(CCEncoded3)
            setMobile(res.data.mobile)

            setBusiness(res.data.business)
            setFirm(res.data.firm)
            const CCEncoded = "XXX XXXX XXXX " + res.data.gst.substring(res.data.gst.length + 4, 10);
            setNewGst(CCEncoded)
            setAadhar(res.data.aadhar)
        })
    }


    useEffect(() => {
        userName()
    }, [])

    const [wait, setWait] = useState(true)

    setTimeout(() => setWait(false), 1000)


    const [isDashboard, setIsDashboard] = useState(true)

    const open = useAppStore((state) => state.dopen)

    const [isBidoffer, setIsBidoffer] = useState(false)
    const [isConfirmedBids, setIsConfirmedBids] = useState(false)

    return (
        <Box sx={{ display: 'flex' }} className="main_container">
            <CssBaseline />
            <Drawer elevation={0} variant="permanent" open={open}>
                <List style={{ height: '100vh', width: '100%', background: '#fff', paddingTop: 150 }} >
                    <img style={{ position: 'relative', top: -18, left: 10 }} src='https://img.freepik.com/free-vector/home-screen-concept-illustration_114360-4703.jpg?size=626&ext=jpg&ga=GA1.1.267965162.1685679529&semt=ais' width='170px' />
                    <ListItem onClick={() => {

                        setIsProfile(false)
                        setisCreateBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)
                        setIsBidoffer(false)
                        setWait(true)
                        window.location.reload()
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsDashboard(true)

                    }} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaPencilAlt fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setIsProfile(false)
                        setIsConfirmedBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)
                        setIsBidoffer(false)
                        setWait(true)

                        setWait(false)
                        setisCreateBids(true)

                    }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaHandPeace fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Create Bids" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setIsProfile(false)
                        setisCreateBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)

                        setWait(true)
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsBidoffer(true)

                    }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaHandPeace fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Bids Offer" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem> */}
                    {/* <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setIsProfile(false)
                        setisCreateBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)
                        setWait(true)
                        setWait(false)
                        setIsBidoffer(false)
                        setIsConfirmedBids(true)

                    }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaCheck fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Confirmed Bids" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem> */}
                    {/* <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setisCreateBids(false)
                        setIsProfile(false)
                        setIsBidoffer(false)

                        setIsprfileItem(false)
                        setIsBidoffer(false)
                        setWait(true)
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsUpdateKyc(true)

                    }} >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaEdit fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Update Kyc" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem disablePadding sx={{ display: 'block', marginTop: 30 }} onClick={() => {
                        setIsDashboard(false)
                        setisCreateBids(false)
                        setIsProfile(false)
                        setIsBidoffer(false)
                        setIsUpdateKyc(false)

                        setIsBidoffer(false)
                        setWait(true)
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsprfileItem(true)

                    }} >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaUser fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
            </Drawer>

            <Box className='no-args w-100 mt-5'>

               
                {
                    isDashboard && (
                        <Box sx={{ width: '100%', marginTop: isAlert ? '3rem' : '2.3rem', padding: '0.8rem' }}>
                            {
                                isAlert && (
                                    <Collapse style={{ cursor: 'pointer' }} onClick={() => {
                                        setIsDashboard(false)
                                        setisCreateBids(false)
                                        setIsProfile(false)
                                        setIsBidoffer(false)
                                        setIsprfileItem(false)
                                        setIsBidoffer(false)
                                        setWait(true)
                                        setWait(false)
                                        setIsUpdateKyc(true)
                                        setIsConfirmedBids(false)
                                    }} in={open3}>
                                        <Alert
                                            severity="warning"
                                        >
                                            Please Complete Your Kyc   <span onClick={() => {
                                                setIsDashboard(false)
                                                setisCreateBids(false)
                                                setIsProfile(false)
                                                setIsBidoffer(false)
                                                setIsConfirmedBids(false)
                                                setIsprfileItem(false)
                                                setIsBidoffer(false)
                                                setWait(true)
                                                setTimeout(() => {
                                                    setWait(false)
                                                    setIsUpdateKyc(true)
                                                }, 1000)
                                            }} style={{ fontSize: '0.9rem', textDecoration: 'underline', cursor: 'pointer' }}>click here</span>
                                        </Alert>
                                    </Collapse>
                                )
                            }
                        </Box>
                    )
                }
                {
                    isDashboard && (
                        <Box style={{ position: 'relative', top: '-3.5rem' }}>
                            <FullWidthGrid/>
                        </Box>
                    )
                }
            </Box>
            
        </Box>
    );
}
