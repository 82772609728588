import './index.css'

const ThumbnailItem = props => {
  const {imagesArray, onClickItem, id} = props
  const {imageUrl, imageAltText} = imagesArray
  const classCreate = id !== imagesArray.id ? 'highlight' : ''
  const activeTab=id===imagesArray.id

  const onClickButton = () => {
    onClickItem(imageUrl, imageAltText)
  }
  return (
    // <li className="li-item">
    //   <button className="btn" type="button" onClick={onClickButton}>
        <img
          src={imagesArray.thumbnailUrl}
        //   className={`small-img ${classCreate}`}
        className={activeTab?`active-tab-item`:`small-img ${classCreate}`}
          alt={imagesArray.thumbnailAltText}
          onMouseMove={onClickButton}
        />
    //   </button>
    // </li>
  )
}
export default ThumbnailItem