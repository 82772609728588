import axios from 'axios'
import React, { useEffect } from 'react'
import { GoArrowUp } from 'react-icons/go'
import './index.css'
import { FaAccusoft, FaAdn, FaCheckCircle, FaSeedling, FaUsers, FaWindowClose } from 'react-icons/fa'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));

const CustomerDashboard = () => {




    return (
        <Box sx={{ flexGrow: 1, marginTop: 3}}>
            <Grid container spacing={2}>
                <Grid style={{ height: '12rem', marginBottom: '0.8rem', }} item xs={6}>
                    <Item elevation={1} sx={{ height: '11.6rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: 'gray' }} >
                            Users
                        </h1>
                        <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaUsers fontSize={38} style={{ position: 'relative' }} color='red' />
                        </div>
                        <p style={{ fontSize: 29, color: 'gold', fontWeight: 'bold', marginTop: 37 }}>0</p>
                        <h1 style={{ fontSize: 13, color: 'gold', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '2rem' }}>
                            All register users
                        </h1>
                    </Item>
                </Grid>
                <Grid style={{ height: '12rem', marginBottom: '0.8rem' }} item xs={6}>
                    <Item elevation={1} sx={{ height: '11.6rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: 'gray' }} >
                            Referral 
                        </h1>
                        <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaSeedling fontSize={38} style={{ position: 'relative' }} color='red' />
                        </div>
                        <p style={{ fontSize: 29, color: 'gold', fontWeight: 'bold', marginTop: 37 }}>0</p>
                        <h1 style={{ fontSize: 13, color: 'gold', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '2rem' }}>
                            All user referral product
                        </h1>
                    </Item>
                </Grid>
               
            </Grid>
        </Box>
    )

}


export default CustomerDashboard