import { Component } from 'react'

import { Container, Card, Row, Col, Form } from 'react-bootstrap'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import './index.css'
import Dashboard from '../Dashboard'
import RegisterPageSlider from '../RegisterPageSlider'


class AdminLoginPage extends Component {

    state = {isActiveBtn:false, username: '', loginEmail: '', loginPass: '', showPasswordErr: false, showEmailErr: false, isFormSubmit: false }


    // login=()=>{
    //     axios.post('https://vyaparapi.emedha.in/login',{
    //        loginEmail,
    //         loginPass
    //     })
    // }

    onChangeUsername = event => {
        this.setState({ username: event.target.value })
    }

    onChangeEmail = event => {
        this.setState({ loginEmail: event.target.value })
    }

    onChangePassword = event => {
        this.setState({ loginPass: event.target.value })
    }

    validEmail = () => {
        const { loginEmail } = this.state
        return loginEmail !== ""
    }

    validPassword = () => {
        const { loginPass } = this.state
        return loginPass !== ""
    }

    onBlurPassword = () => {
        const validPassword = this.validPassword()
        this.setState({ showPasswordErr: !validPassword })
    }

    onBlurEmail = () => {
        const validEmail = this.validEmail()
        this.setState({ showEmailErr: !validEmail })
    }

    onChange=()=>{
        this.setState({isActiveBtn:true})
    }

    onSubmitForm = (event) => {
        event.preventDefault()
        const validEmail = this.validEmail()
        const validPassword = this.validPassword()

        const email = {
            loginEmail: this.state.loginEmail
        };

        const password = {
            loginPass: this.state.loginPass
        }

        const loginPass = password.loginPass
        const loginEmail = email.loginEmail

        axios.post(`https://vyaparapi.emedha.in/admin`, { loginEmail, loginPass })

            .then(res => {
                if (res.data === "Login SuccessFully enter user Dashboard") {
                   
                    window.location.href = '/admin-store';
                    sessionStorage.setItem('adminEmail',loginEmail)
            
                } else {
                    alert(res.data)

                }
            })


        if (validEmail && validPassword) {
            this.setState({ isFormSubmit: true })
        } else {
            this.setState({
                showEmailErr: !validEmail,
                showPasswordErr: !validPassword,
                isFormSubmit: false

            })

        }

    }
    

    render() {
        const {isActiveBtn, username, loginEmail, loginPass, showPasswordErr, showEmailErr, isFormSubmit } = this.state
        console.log(username)

        return (


            <div className='container-fluid admin-login-container'>
                <div className='row login-card' style={{ backgroundColor: 'white' }}>
                    <div className='col-lg-5 mx-auto login-logo mt-5'>
                     <img src="https://img.freepik.com/free-vector/follow-me-social-business-theme-design_24877-52233.jpg?size=626&ext=jpg&ga=GA1.2.490759954.1685970921&semt=sph" className='login-logo' style={{ borderRadius: 8, width: 380 }} alt='logo'></img> 
                      <h1 className='admin-login-heading'> Admin Login </h1>  
                        <br />

                       
                    </div>

                    <div className='col-lg-5 mx-auto m-2 border-0 login-form-card' style={{ border: '1px solid black', borderRadius: 5 }}>
                        <Card className='m-2 '>
                            <Card.Header style={{ backgroundColor: 'white' }} >
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <h1 style={{ fontSize: 18, padding: 10, color: 'black' }}>Please Login</h1>
                                    <img src='https://play-lh.googleusercontent.com/NZaFTTPY51Zv7SvfhssvX26hlNfJB1O8HLjdkPg1a1Zepgi5ORFEaN1ZohgFbB1-lp41' style={{ width: 70 }} alt='logo' />

                                </div>

                            </Card.Header>

                            <Form onSubmit={this.onSubmitForm} className='p-3'>
                                <Form.Label style={{ marginTop: 10, marginLeft: 2, color: 'black', fontWeight: '600' }}>Email Address</Form.Label>
                                <Form.Control type='text' id='loginEmail' placeholder="Enter Email" value={loginEmail} onChange={this.onChangeEmail} onBlur={this.onBlurEmail} className='input-box' />
                                {showEmailErr && <p style={{ color: 'red', marginBottom: 0 }}>Required*</p>}
                                <Form.Label style={{ marginTop: 10, marginLeft: 2, color: 'black', fontWeight: '600' }}>Password</Form.Label>
                                <Form.Control type='password' id='loginPass' placeholder="Enter Password" value={loginPass} onChange={this.onChangePassword} onBlur={this.onBlurPassword} className='input-box' />
                                {showPasswordErr && <p style={{ color: 'red', marginBottom: 0 }}>Required*</p>}

                                <div className='text-center mt-4'>

                                    <ReCAPTCHA
                                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                        onChange={this.onChange}
                                    />
                                </div>

                            {isActiveBtn?<button className='log-button' style={{ backgroundColor: 'rgb(13, 10, 70)', borderRadius: 5 }}>Login</button>:<button  style={{ backgroundColor: 'transparent', borderRadius: 5, width:150,marginTop:30,height:38}} disabled>Login</button>}    
                                
                            </Form>
                        </Card>
                    </div>
                </div>
            </div>

        )
    }
}

export default AdminLoginPage



