import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AadharKyc from '../AadharKyc/AadharKyc';
import GstKyc from '../GstKyc/GstKyc';

export default function LabTabs() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='shadow col-7 mx-auto' style={{marginBottom:10}} >
            <Box className="" sx={{ width: '100%', typography: 'body1', marginTop: '9rem' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Aadhar Ky" value="1" />
                            <Tab label="GST Kyc" value="2" />
                             
                        </TabList>
                    </Box>
                    <TabPanel value="1"><AadharKyc></AadharKyc></TabPanel>
                    <TabPanel value="2"><GstKyc></GstKyc></TabPanel>
                   
                </TabContext>
            </Box>
        </div>
    );
}